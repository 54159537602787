@use 'base/colors';
@use 'base/root';
@use 'base/mixins';

@use 'layout/header';
@use 'layout/nav';
@use 'layout/footer';
@use 'components/cv';
@use 'components/portfolio';
@use 'components/instagram';
@use 'components/skills';
@use 'components/contact';
@use 'components/buttons';

@import url('https://fonts.googleapis.com/css2?family=Grand+Hotel&family=Montserrat:wght@100;400;600&display=swap');

html {
  box-sizing: border-box;
}
*,
::before,
::after {
  box-sizing: inherit;
}

body {
  font-family: root.$font-default;
  font-size: 16px;
  background-color: colors.$light;
  color: colors.$dark;
  line-height: 1.8;
  margin: 0;
  position: relative;
  max-width: 1300px !important;
  margin: 0 auto;
}

h1,
h2,
h3 {
  color: colors.$primary;
}

h1 {
  text-align: center;
  color: colors.$primaryLight;
  font-family: root.$font-special;
  font-size: root.$xxLarge;
  font-weight: 200;

  @include mixins.special(500px) {
    font-size: 300%;
  }

  @include mixins.tablet {
    font-size: 400%;
  }
}

h2 {
  text-align: center;
  font-size: root.$xLarge;
  font-family: root.$font-special;
  font-weight: 200;
  margin-top: root.$spacer;
  margin-bottom: 0.5em;

  @include mixins.special(500px) {
    font-size: 200%;
  }

  @include mixins.tablet {
    font-size: 300%;
  }
}

h3 {
  font-size: root.$large;
  font-weight: 400;

  @include mixins.special(500px) {
    font-size: 150%;
  }

  @include mixins.tablet {
    font-size: 200%;
  }
}

.link {
  color: colors.$primary;
  position: relative;
  text-decoration: none;
  transition: background-color 0.25s;

  &::after {
    background: linear-gradient(
      120deg,
      colors.$primaryLight 0%,
      colors.$primaryLight 50%,
      colors.$primary 100%
    );

    position: absolute;
    right: 0px;
    content: '';
    bottom: -2px;
    width: 100%;
    height: 3px;
  }

  &:hover,
  &:focus {
    background: linear-gradient(
      120deg,
      colors.$primaryLight 0%,
      colors.$primaryLight 50%,
      colors.$primary 100%
    );
  }
}

.main {
  padding: root.$spacer / 2;

  @include mixins.tablet {
    padding: root.$spacer;
  }

  @include mixins.large {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}

.banner {
  &__img {
    width: 100vw;
    max-width: 100%;
  }
}

.bold {
  font-weight: 600;
}

.center {
  text-align: center;
}

@keyframes startScale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
