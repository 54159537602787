@use '../base/colors';
@use '../base/root';
@use '../base/mixins';

.button {
  text-transform: uppercase;
  background-color: colors.$primary;
  border: colors.$primary solid 1px;
  color: colors.$light;
  font-weight: 600;
  padding: 0.7rem 1.4rem;
  box-shadow: 3px 3px 5px rgb(0 0 0 / 10%), 0 15px 35px 0 rgb(0 0 0 / 10%);
  display: inline-block;

  &--secondary {
    background-color: colors.$primaryLight;
    color: colors.$primary;
    border: colors.$primary solid 1px;
    margin: -3px;
    text-decoration: none;
    font-weight: 600;
  }
  &__row {
    text-align: center;
  }

  cursor: pointer;
  transition: background-color 0.3s;

  &:hover,
  &:focus {
    background-color: #7b4a5e;
  }
  &:active {
    box-shadow: 1px 1px 3px rgb(0 0 0 / 10%), 0 15px 35px 0 rgb(0 0 0 / 10%);
    transform: translate(2px, 2px);
  }

  &__link {
    color: colors.$dark;
    text-decoration: none;
    font-weight: 600;

    &--form {
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__cv {
    text-align: center;
  }

  &--targetBlank {
    position: relative;
    &::after {
      content: '';
      right: 2px;
      bottom: 2px;
      width: 10px;
      height: 10px;
      background-image: url(../../Components/Home/img/targetBlank.svg);
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
    }
  }
  &--coaching {
    margin-left: 1rem;
  }
}
