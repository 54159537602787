@use '../base/colors';
@use '../base/root';
@use '../base/mixins';

.banner {
  color: colors.$light;
  max-width: 1200px;
  margin: 0 auto 0 auto;

  &__info {
    background-color: colors.$primary;
    text-align: center;
    position: relative;
  }

  &__title {
    margin: 0;
    line-height: 65px;

    @include mixins.special(850px) {
      line-height: 84px;
    }
  }

  &__text {
    font-size: 80%;
    margin: -0.5em 0 0 0;

    @include mixins.special(850px) {
      position: absolute;
      bottom: 0;
      right: 1em;
      height: 100%;
      text-align: left;
      line-height: 1.5;
      padding-top: 2em;
    }
    @include mixins.special(940px) {
      right: 2em;
    }

    @include mixins.special(1000px) {
      right: 90px;
    }
  }

  &__box {
    height: 230px;
    background-image: url(../../Components/Header/img/slavkaMobile.jpg);
    background-attachment: fixed;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0px 80px;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;

    @include mixins.special(350px) {
      height: 280px;
    }

    @include mixins.special(485px) {
      height: 330px;
    }

    @include mixins.special(625px) {
      height: 430px;
      background-image: url(../../Components/Header/img/slavkaWeb.jpg);
    }

    @include mixins.special(700px) {
      height: 350px;
    }
    @include mixins.special(800px) {
      height: 400px;
    }

    @include mixins.special(950px) {
      height: 500px;
    }

    @include mixins.special(1400px) {
      height: 550px;
      background-position: center 80px;
    }
  }
}
