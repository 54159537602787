@use '../base/colors';
@use '../base/root';
@use '../base/mixins';

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: colors.$light;
  z-index: 100;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  border: none;
  outline: none;

  @include mixins.tablet {
    position: static;

    height: 100px;
    align-items: flex-start;
  }
  @include mixins.large {
    height: 120px;
    align-items: flex-start;
  }

  &__logo {
    cursor: pointer;
    padding: 10px;
    height: 100%;
    transition: transform 1s;
    &:hover {
      transform: scale(1.05);
    }

    @include mixins.tablet {
      padding: calc(root.$spacer / 2);
      height: 100px;
    }

    @include mixins.large {
      height: 120px;
    }
  }

  &__hamburger {
    width: 40px;
    position: relative;
    height: 37px;
    right: 20px;
    top: 0;
    z-index: 1000;
    cursor: pointer;
    align-self: center;
    transition: transform 1s;
    &:hover {
      transform: scale(1.05);
    }
    @include mixins.tablet {
      display: none;
    }
  }

  &__rows {
    background: colors.$primary;
    background-size: 100% 4px;
    background-position: left top;
    background-repeat: no-repeat;
    width: 100%;
    height: 5px;
    display: block;
    top: 15px;
    left: 0;
    position: absolute;
    -webkit-transition: all 500ms;
    -moz-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;
    cursor: pointer;
    -webkit-box-shadow: 0 0 3px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 3px rgb(0 0 0 / 75%);

    &::before {
      background: colors.$primary;
      position: absolute;
      left: 0;
      content: '';
      top: -15px;
      left: 0;
      width: 100%;
      height: 5px;
      -webkit-transition: all 500ms;
      -moz-transition: all 500ms;
      -o-transition: all 500ms;
      transition: all 500ms;
      cursor: pointer;
      -webkit-box-shadow: 0 0 3px rgb(0 0 0 / 75%);
      -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
      box-shadow: 0 0 3px rgb(0 0 0 / 75%);
    }

    &::after {
      background: colors.$primary;
      position: absolute;
      left: 0;
      content: '';
      bottom: -15px;
      left: 0;
      width: 100%;
      height: 5px;
      -webkit-transition: all 500ms;
      -moz-transition: all 500ms;
      -o-transition: all 500ms;
      transition: all 500ms;
      cursor: pointer;
      -webkit-box-shadow: 0 0 3px rgb(0 0 0 / 75%);
      -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.75);
      box-shadow: 0 0 3px rgb(0 0 0 / 75%);
    }
  }

  &-open {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100vw;
    z-index: 1000;
    background-color: colors.$primary;
    text-align: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: root.$large;
    height: calc(100vh - 75px);
  }

  &__item {
    height: calc((100vh - 75px) / 6);
    line-height: calc((100vh - 75px) / 6);
    margin: 0;

    @include mixins.tablet {
      color: colors.$dark;
      height: 150px;
    }
  }
}

.navigation__hamburger.open .navigation__rows::before {
  transform: rotate(45deg);
  top: 0px;
}

.navigation__hamburger.open .navigation__rows {
  background: colors.$light;
  box-shadow: none;
}

.navigation__hamburger.open .navigation__rows::after {
  transform: rotate(-45deg);
  top: 0px;
}

.hamburger__close {
  position: absolute;
  top: -80px;
  right: 0px;
  height: 80px;
  width: 100px;
  z-index: 10000000;
  background-color: transparent;
}

.navigation__large {
  display: none;

  @include mixins.tablet {
    display: flex;
    list-style-type: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
  }
}

.navigationLarge__link {
  color: colors.$light;
  text-decoration: none;
  width: 100%;
  vertical-align: middle;
  display: inline-block;

  &:hover,
  &:focus {
    color: colors.$primary;
    background-color: colors.$primaryLight;
  }

  @include mixins.tablet {
    text-decoration: none;
    line-height: 100px;
    display: block;
    padding: 0 calc(root.$spacer / 2);
    color: colors.$primary;

    &:hover,
    &:focus {
      background-color: colors.$primaryLight;
    }

    @include mixins.large {
      padding: 0 root.$spacer;
      height: 120px;
      line-height: 120px;
    }
  }
}

.activeItem {
  background-color: colors.$light;
  color: colors.$primary;
  border-top: 1px solid colors.$primary;
  border-bottom: 1px solid colors.$primary;

  @include mixins.tablet {
    background-color: colors.$primary;
    color: colors.$light;
    border-top: none;
    border-bottom: none;
  }
}
