@use '../base/colors';
@use '../base/root';
@use '../base/mixins';

.skills {
  &__grid {
    margin-bottom: root.$spacer;
    @include mixins.special(400px) {
      grid-template-columns: repeat(2, 1fr);
      display: grid;
      gap: root.$spacer;
    }

    @include mixins.special(580px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include mixins.special(770px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include mixins.large {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  &__item {
    background-image: url(../../Components/Skills/img/kolo.svg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    vertical-align: center;
    padding: 0;
    margin: 0;
    aspect-ratio: 1/1;
    position: relative;
    padding-top: 30%;
    margin: root.$spacer/2 root.$spacer;
    @include mixins.special(400px) {
      margin: 0;
    }
  }

  &__icon {
    height: 3rem;

    @include mixins.special(500) {
      margin-top: root.$spacer * 2;
      height: 4rem;
    }
  }

  &__title {
    font-size: 125%;
    margin-bottom: 0;
    margin-top: 0;

    @include mixins.special(500) {
      margin-bottom: root.$spacer * 2;
    }
  }
}
