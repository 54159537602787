@use '../base/colors';
@use '../base/root';
@use '../base/mixins';

.footer {
  border-top: 1px solid colors.$primary;
  padding: 1rem;
  text-align: center;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  margin-top: root.$spacer;

  @include mixins.tablet {
    margin-top: root.$xxLarge;
  }
}
