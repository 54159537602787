@use '../base/colors';
@use '../base/root';
@use '../base/mixins';

.item {
  text-align: center;
  margin: root.$xLarge 0;
  border-radius: root.$radius;
  background-color: colors.$light;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 10%), 0 15px 35px 0 rgb(0 0 0 / 5%);
  transition: all 120ms ease;
  overflow: hidden;

  &__imgBorder {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  @include mixins.special(500px) {
    margin: 0 root.$xLarge calc(root.$xLarge + root.$spacer/2) root.$xLarge;
  }

  @include mixins.special(800px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  &__img {
    width: 100%;
    border-radius: root.$radius root.$radius 0 0;
    display: block;
    transition: transform 1s;

    &:hover {
      transform: scale(1.1);
    }
    @include mixins.special(800px) {
      width: 100%;
      border-radius: root.$radius 0 0 root.$radius;
    }
  }

  &__text {
    padding: root.$spacer;

    @include mixins.special(800px) {
      padding: 0 root.$spacer 0 root.$spacer;
      text-align: left;
    }
  }

  &__id {
    font-size: root.$xxLarge;
    margin: 0;
  }

  &__title {
    margin-top: 0;
  }
}

.prototypNo {
  display: none;
}

.prototypDisplay {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1em;
  @include mixins.special(800px) {
    display: inline-block;
    width: auto;
    margin: 0 1em 1em 0;
  }
}
