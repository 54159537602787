$primary: #582c3e;
$primaryLight: #dfc7d0;

$secondary: #fcdc4d;

$grey: #b8c5d6;
$secondary2: #133c55;

$light: #fffafc;
$dark: #0a0507;
$error: #c73e1d;
$success: #337357;
$formMessage: #4a6fa5;
