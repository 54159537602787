$spacer: 32px;
$spacerL: 48px;
$spacerXL: 64px;

$font-default: 'Montserrat', 'Roboto', sans-serif;
$font-special: 'Grand Hotel', cursive;

$small: 14px;
$normal: 16px;
$large: 24px;
$xLarge: 32px;
$xxLarge: 48px;

$radius: 8px;
