@use '../base/colors';
@use '../base/root';
@use '../base/mixins';

.required {
  color: colors.$error;
}

.contact {
  &__question {
    text-align: center;
    font-weight: bold;
    animation: startScale 1s;
    margin-bottom: -10px;

    @include mixins.tablet {
      font-size: root.$large;
    }
  }

  &__socialIcons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    margin-top: root.$spacer;
    &--center {
      display: flex;
      flex-direction: column;
      align-items: left;

      @include mixins.special(850px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
      }
    }
  }

  &__icon {
    width: 2rem;
    margin-right: 1em;
  }
  &__row {
    display: flex;
    align-items: center;
    width: 250px;
    margin: root.$spacer / 2 auto;
  }
}

.form {
  width: 100%;

  @include mixins.special(900px) {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  &__field {
    margin-bottom: root.$spacer / 2;
    position: relative;
  }

  &__label {
    font-size: root.$small;
    color: colors.$dark;
  }

  &__input {
    width: 100%;
    outline: 0;
    border: 1px solid colors.$dark;
    border-radius: 3px;
    padding: 0.5rem 1rem;

    &:focus,
    &:active {
      border: 2px solid colors.$primary;
      border-left: 5px solid colors.$primary;
      outline: 0;
    }
  }
  &__legend {
    font-size: root.$small;
    color: colors.$dark;
  }

  &__textarea {
    width: 100%;
    border: 1px solid colors.$dark;
    border-radius: 3px;
    padding: 0.5rem 1rem;

    &:focus,
    &:active {
      border: 2px solid colors.$primary;
      border-left: 5px solid colors.$primary;
      outline: 0;
    }
  }
}

.validBorder {
  border: 2px solid colors.$success;
  border-left: 5px solid colors.$success;
}

.invalidBorder {
  border: 2px solid colors.$error;
  border-left: 5px solid colors.$error;
}

.error-message {
  color: colors.$error;
  text-align: center;
  font-weight: 600;
}

.success-message {
  color: colors.$success;
  text-align: center;
  font-weight: 600;
}

.form-message {
  font-size: root.$large;
  color: colors.$formMessage;
}

.infoField {
  font-size: small;
  position: absolute;
  top: 0.5em;
  right: 0;
}

.right {
  text-align: right;
  margin-top: 0;
}
