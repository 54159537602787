@mixin tablet {
  @media screen and (min-width: 700px) {
    @content;
  }
}

@mixin large {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin special($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}
