@use '../base/colors';
@use '../base/root';
@use '../base/mixins';

.instagram {
  &__mobile {
    @include mixins.tablet {
      display: none;
    }
  }

  &__tablet {
    display: none;

    @include mixins.tablet {
      display: inline-block;
    }

    @include mixins.large {
      display: none;
    }
  }

  &__web {
    display: none;

    @include mixins.large {
      display: inline-block;
    }
  }
}

.instagram-gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px 10px;

  @include mixins.tablet {
    grid-template-columns: repeat(3, 1fr);
    gap: root.$spacer/2;
  }
  @include mixins.large {
    grid-template-columns: repeat(4, 1fr);
    gap: root.$spacer;
  }
}

.instagram-image {
  width: 100%;
}
